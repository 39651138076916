<template>
  <template v-if="value && value?.length > 0">
    <span v-for="row in value" :key="row.id">
      {{ row.partner_name }}
      <span class="badge badge-outline me-3">
        {{ row.purchase_price }} {{ row.currency_code }}
      </span>
    </span>
  </template>
</template>

<script>
export default {
  name: "ProductVariantPurchasePrices",
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
